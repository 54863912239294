const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const mq = Object.keys(breakpoints)
  .map(key => [key, breakpoints[key]])
  .reduce((prev, [key, breakpoint]) => {
    const p = { ...prev }
    p[key] = `@media (min-width: ${breakpoint}px)`
    return p
  }, {})

export default mq
