// Icon
import React from "react"

function Icon() {
  return (
    <svg height="15" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.793 14.705l-6.5-6.537a1.01 1.01 0 010-1.422l1.414-1.423a.996.996 0 011.414 0L7.5 9.727 16.879.295a.996.996 0 011.414 0l1.414 1.422c.39.393.39 1.03 0 1.422l-11.5 11.566a.996.996 0 01-1.414 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
